import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilService {

  private imageFileTypes: Array<string> = [
    'image/apng',
    'image/bmp',
    'image/gif',
    'image/jpeg',
    'image/png',
    'image/svg+xml',
  ];

  darkMode: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {
  }

  validateFile(file: File): boolean {
    return this.imageFileTypes.includes(file.type);
  }

  setDarkMode(darkMode: boolean): void {
    localStorage.setItem('darkMode', darkMode ? 'dark' : null);
    this.darkMode.emit(darkMode);
  }
}
