import { APP_INITIALIZER, NgModule } from '@angular/core';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NavBarComponent } from './layout/main-layout/navbar/nav-bar.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { SidebarComponent } from './layout/main-layout/sidebar/sidebar.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatButtonModule } from '@angular/material/button';
import { AuthenticationService } from './services/authentication/authentication.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderService } from './services/loader/loader.service';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { InitializerService } from './services/initializer/initializer.service';
import { ThemeSwitchComponent } from './layout/main-layout/navbar/theme-switch/theme-switch.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [MainLayoutComponent, NavBarComponent, SidebarComponent, ThemeSwitchComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    MatSidenavModule,
    TranslateModule,
    MatToolbarModule,
    MatIconModule,
    MatChipsModule,
    MatButtonModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    AngularSvgIconModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    SharedModule,
  ],
  providers: [
    AuthenticationService,
    LoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      deps: [InitializerService],
      multi: true,
      useFactory: (initializerService: InitializerService): () => any => {
        return (): any => {
          return initializerService.initApp();
        };
      },
    }],
  exports: [MainLayoutComponent],
})
export class CoreModule {
}
