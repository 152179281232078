import { Injectable } from '@angular/core';
import { AuthenticationService } from '../authentication/authentication.service';
import { now } from 'moment';

@Injectable({
  providedIn: 'root',
})
export class InitializerService {
  private timeout: number;

  constructor(private authenticationService: AuthenticationService) {
  }

  public async initApp(): Promise<void> {
    await this.loadToken();
    this.setupTokenRefresh();
  }

  public async loadToken(): Promise<void> {
    try {
      await this.authenticationService.reloadToken().toPromise();
    } catch (e) {
      this.authenticationService.makeLogout();
    }
  }

  public handleTokenRefresh(): void {
    const expDate: Date = this.authenticationService.getExpirationDate();
    if (expDate) {
      const expSeconds: number = expDate.valueOf() - now().valueOf() - 5000;
      if (expSeconds > 0) {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }
        // @ts-ignore
        this.timeout = setTimeout(() => {
          this.authenticationService.refreshToken().toPromise().then(() => {
            this.handleTokenRefresh();
          }, (error: any) => {
            this.authenticationService.makeLogout();
          });
        }, expSeconds);
      } else {
        this.authenticationService.refreshToken().toPromise().then(() => {
          this.handleTokenRefresh();
        }, (error: any) => {
          this.authenticationService.makeLogout();
        });
      }
    }
  }

  public setupTokenRefresh(): void {
    this.handleTokenRefresh();
  }

}
