// tslint:disable-next-line:typedef
export const environment = {
  production: true,
  serverUrl: '/api/public',
  apiUrl: '/api',
  imagesFolder: {
    communities: '/images/communities/',
    tournaments: '/images/tournaments/',
  },
};
