import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UtilService } from '../../../../services/util/util.service';

@Component({
  selector: 'app-theme-switch',
  templateUrl: './theme-switch.component.html',
  styleUrls: ['./theme-switch.component.scss'],
})
export class ThemeSwitchComponent implements OnInit {

  toggleControl: UntypedFormControl = new UntypedFormControl(false);

  constructor(private utilsService: UtilService) {
  }

  ngOnInit(): void {
    this.toggleControl.setValue(!!localStorage.getItem('darkMode'));
    this.toggleControl.valueChanges.subscribe((darkMode: boolean) => {
      this.utilsService.setDarkMode(darkMode);
    });
  }

}
