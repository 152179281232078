<form [formGroup]='searchForm'>
  <mat-form-field>
    <input matInput [matAutocomplete]='auto' formControlName='searchBar'>
    <mat-autocomplete #auto='matAutocomplete' [displayWith]='displayFn'>
      <mat-option *ngIf='isLoading' class='is-loading'>Loading...</mat-option>
      <ng-container *ngIf='!isLoading'>
        <mat-option *ngFor='let option of searchResult' [value]='option.searchableString' (click)='navigateTo(option.routerLink)'>
          <span>{{ option.searchableString }}<small *ngIf='option.additionalData'> - {{option.additionalData}}</small></span>
        </mat-option>
      </ng-container>
    </mat-autocomplete>
    <button *ngIf="searchForm.get('searchBar').value" matSuffix mat-icon-button aria-label="Clear" (click)="clean()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
</form>
