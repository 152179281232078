import { Component, ElementRef, Input } from '@angular/core';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent {
  @Input()
  screenWidth: number;

  @Input()
  sidenav: MatSidenav;

  constructor(public authenticationService: AuthenticationService,
              private router: Router,
              private snackBar: MatSnackBar) {
  }

  logout(): void {
    this.authenticationService.makeLogout();
    this.snackBar.open('Logout success', 'Close', {
      duration: 2000,
    });
    this.router.navigateByUrl('/');
  }

}
