<div class="sidebar">
  <button mat-button disabled>{{'SIDEBAR.SETTINGS' | translate}}</button>
  <mat-menu #menu="matMenu" [hidden]="!isRoot">
    <button mat-menu-item [routerLink]="'/users'">{{'SIDEBAR.USERS' | translate}}</button>
    <button mat-menu-item [routerLink]="'/player-requests'">{{'SIDEBAR.PLAYER_REQUESTS' | translate}}</button>
    <button mat-menu-item [routerLink]="'/years-management'">{{'SIDEBAR.YEARS_MANAGEMENT' | translate}}</button>
  </mat-menu>
  <button mat-button [matMenuTriggerFor]="menu" aria-label="Admin">
    {{'SIDEBAR.ADMIN' | translate}}
  </button>
  <button mat-button [routerLink]="'/my-page'">{{'SIDEBAR.MY_PAGE' | translate}}</button>
  <hr/>
  <button mat-button [routerLink]="'/documents'">{{'SIDEBAR.DOCUMENTS' | translate}}</button>
  <button mat-button [routerLink]="'/calendar'">{{'SIDEBAR.CALENDAR' | translate}}</button>
  <button mat-button [routerLink]="'/ranking'">{{'SIDEBAR.RANKING' | translate}}</button>
  <button mat-button [routerLink]="'/communities'">{{'SIDEBAR.COMMUNITIES' | translate}}</button>
  <button mat-button [routerLink]="'/tournaments'">{{'SIDEBAR.TOURNAMENTS' | translate}}</button>
  <button mat-button [routerLink]="'/hall-of-fame'">{{'SIDEBAR.HALL_OF_FAME' | translate}}</button>
  <button mat-button [routerLink]="'/championships'">{{'SIDEBAR.CHAMPIONSHIPS' | translate}}</button>
  <button mat-button [routerLink]="'/players'">{{'SIDEBAR.PLAYERS' | translate}}</button>
  <button mat-button [routerLink]="'/players/compare'">{{'SIDEBAR.PLAYERS_COMPARE' | translate}}</button>
  <button mat-button [routerLink]="'/licenses'">{{'SIDEBAR.LICENSES' | translate}}</button>
  <button mat-button [routerLink]="'/seeding'">{{'SIDEBAR.SEEDING' | translate}}</button>
  <button mat-button [routerLink]="'/ip-checker'">{{'SIDEBAR.IP_CHECKER' | translate}}</button>
</div>
