import { Component, OnInit } from '@angular/core';
import { SearchResult, SearchService } from '../../../core/services/search/search.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { debounceTime, filter, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss'],
})
export class SearchBoxComponent implements OnInit {
  isLoading: boolean;
  searchForm: UntypedFormGroup;
  searchResult: Array<SearchResult> = [];

  constructor(private fb: UntypedFormBuilder, private searchService: SearchService, private router: Router) {
  }

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      searchBar: '',
    });
    this.onChanges();
  }

  onChanges(): void {
    this.searchForm.get('searchBar').valueChanges.pipe(
      filter((data: any) => data.trim().length > 0),
      debounceTime(500),
      switchMap((query: string) => {
        if (query.length >= 3) {
          return query ? this.searchService.search({ q: query }) : of([]);
        } else {
          this.searchResult = [];
          return of([]);
        }
      }),
    ).subscribe((data: Array<SearchResult>) => {
      this.searchResult = data;
    });
  }

  displayFn(s: SearchResult): string {
    return s.searchableString;
  }

  clean(): void {
    this.searchResult = [];
    this.searchForm.get('searchBar').setValue('');
  }

  navigateTo(link: string): void {
    this.clean();
    this.router.navigateByUrl(link);
  }

}
