import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { MatIconModule } from '@angular/material/icon';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatChipsModule } from '@angular/material/chips';
import { MatSidenavModule } from '@angular/material/sidenav';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MessageDialogComponent } from './components/message-dialog/message-dialog.component';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTreeModule } from '@angular/material/tree';
import {
  NGX_MAT_DATE_FORMATS,
  NgxMatDateFormats,
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { MatSliderModule } from '@angular/material/slider';
import { ClassificationTypeSelectorComponent } from './components/classification-type-selector/classification-type-selector.component';
import { ResultsTableComponent } from './components/results-table/results-table.component';
import { SearchBoxComponent } from './components/search-box/search-box.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ResultsProgressionComponent } from './components/results-progression/results-progression.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgChartsModule } from 'ng2-charts';
import { CalendarBarComponent } from './components/calendar-bar/calendar-bar.component';

// If using Moment
const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: 'ddd DD/MM/YYYY - HH:mm',
  },
  display: {
    dateInput: 'ddd DD/MM/YYYY - HH:mm',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [MessageDialogComponent, ClassificationTypeSelectorComponent, ResultsTableComponent, SearchBoxComponent, ResultsProgressionComponent, CalendarBarComponent],
  providers: [
    {
      provide: NGX_MAT_DATE_FORMATS,
      useValue: CUSTOM_DATE_FORMATS,
    }],
  imports: [
    // vendor
    CommonModule,
    RouterModule,
    TranslateModule,

    // material
    MatCardModule,
    MatButtonModule,
    MatChipsModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatSidenavModule,
    MatSelectModule,
    MatSnackBarModule,
    MatSortModule,
    MatToolbarModule,
    MatTableModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatListModule,
    MatSlideToggleModule,
    MatTreeModule,
    MatSliderModule,
    NgxMatTimepickerModule,
    NgxMatDatetimePickerModule,
    NgxMatMomentModule,
    MaterialFileInputModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    AngularSvgIconModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    NgChartsModule

  ], exports: [
    MessageDialogComponent,
    ResultsProgressionComponent,
    // vendor
    CommonModule,
    RouterModule,
    TranslateModule,

    // material
    MatCardModule,
    MatButtonModule,
    MatChipsModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatSidenavModule,
    MatSelectModule,
    MatSnackBarModule,
    MatSortModule,
    MatToolbarModule,
    MatTableModule,
    MatPaginatorModule,
    MaterialFileInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatListModule,
    MatSlideToggleModule,
    MatTreeModule,
    MatSliderModule,
    NgxMatTimepickerModule,
    NgxMatDatetimePickerModule,
    NgxMatMomentModule,

    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    AngularSvgIconModule,
    ClassificationTypeSelectorComponent,
    ResultsTableComponent,
    SearchBoxComponent,
    CalendarBarComponent,
  ],
})
export class SharedModule {
}
