import { Role } from '../role/models/role';
import { Injectable } from '@angular/core';
import { DataResponse } from '../data-response';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ParsedUrlQueryInput, stringify } from 'querystring';
import { Player } from '../player/models/player';
import { Community } from '../communities/models/community';
import { Tournament } from '../tournament/models/tournament';
import { TournamentEdition } from '../tournament/models/tournament-edition';

@Injectable({
  providedIn: 'root',
})
export class SearchService {

  constructor(private http: HttpClient) {
  }

  search(filter: SearchFilter): Observable<Array<SearchResult>> {
    return this.http.get<DataResponse<SearchResult>>(`${environment.apiUrl}/search?${stringify(filter)}`).pipe(
      map((d: DataResponse<SearchResult>) => {
          return d.data.map((s: SearchResult) => {
            switch (s.searchType) {
              case SearchType.PLAYER:
                s.routerLink = `/players/view/${s.player.id}`;
                break;
              case SearchType.COMMUNITY:
                s.routerLink = `/communities/view/${s.id}`;
                break;
              case SearchType.TOURNAMENT:
                s.routerLink = `/tournaments/view/${s.tournament.id}`;
                break;
              case SearchType.TOURNAMENT_EDITION:
                s.routerLink = `/tournaments/${s.tournament.id}/edition/${s.tournamentEdition.id}`;
                break;
              case SearchType.TOURNAMENT_EVENT_EDITION:
                s.routerLink = `/tournaments/${s.tournament.id}/edition/${s.tournamentEdition.id}/event/${s.id}`;
                break;
            }
            return s;
          });
        },
      ));
  }
}

export interface SearchFilter extends ParsedUrlQueryInput {
  q?: string;
}

export interface SearchResult {
  id: number;
  searchableString: string;
  player?: Player;
  community?: Community;
  tournament?: Tournament;
  tournamentEdition?: TournamentEdition;
  searchType: SearchType;
  routerLink: string;
  additionalData?: string;
}

export enum SearchType {
  PLAYER = 'PLAYER',
  COMMUNITY = 'COMMUNITY',
  TOURNAMENT = 'TOURNAMENT',
  TOURNAMENT_EDITION = 'TOURNAMENT_EDITION',
  TOURNAMENT_EVENT_EDITION = 'TOURNAMENT_EVENT_EDITION'
}
