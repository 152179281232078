import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HallOfFameModule } from './features/hall-of-fame/hall-of-fame.module';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home',
  },
  {
    path: 'documents',
    loadChildren: () => import('./features/documents/documents.module').then((m: any) => m.DocumentsModule),
  },
  {
    path: 'home',
    loadChildren: () => import('./features/home/home.module').then((m: any) => m.HomeModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./features/login/login.module').then((m: any) => m.LoginModule),
  },
  {
    path: 'register',
    loadChildren: () => import('./features/register/register.module').then((m: any) => m.RegisterModule),
  },
  {
    path: 'seeding',
    loadChildren: () => import('./features/seeding/seeding.module').then((m: any) => m.SeedingModule),
  },
  {
    path: 'communities',
    loadChildren: () =>
      import('./features/community/community.module').then((m: any) => m.CommunityModule),
  },
  {
    path: 'players',
    loadChildren: () =>
      import('./features/players/players.module').then((m: any) => m.PlayersModule),
  },
  {
    path: 'licenses',
    loadChildren: () =>
      import('./features/licenses/licenses.module').then((m: any) => m.LicensesModule),
  },
  {
    path: 'tournaments',
    loadChildren: () =>
      import('./features/tournaments/tournaments.module').then((m: any) => m.TournamentsModule),
  },
  {
    path: 'ranking',
    loadChildren: () =>
      import('./features/ranking/ranking.module').then((m: any) => m.RankingModule),
  },
  {
    path: 'calendar',
    loadChildren: () =>
      import('./features/calendar/calendar.module').then((m: any) => m.CalendarModule),
  },
  {
    path: 'users',
    loadChildren: () =>
      import('./features/users/users.module').then((m: any) => m.UsersModule),
  },
  {
    path: 'ip-checker',
    loadChildren: () =>
      import('./features/ip-checker/ip-checker.module').then((m: any) => m.IpCheckerModule),
  },
  {
    path: 'my-page',
    loadChildren: () =>
      import('./features/my-page/my-page.module').then((m: any) => m.MyPageModule),
  },
  {
    path: 'player-requests',
    loadChildren: () =>
      import('./features/player-requests/player-requests.module').then((m: any) => m.PlayerRequestsModule),
  },
  {
    path: 'years-management',
    loadChildren: () =>
      import('./features/years-management/years-management.module').then((m: any) => m.YearsManagementModule),
  },
  {
    path: 'search',
    loadChildren: () =>
      import('./features/search/search.module').then((m: any) => m.SearchModule),
  },
  {
    path: 'championships',
    loadChildren: () =>
      import('./features/championships/championships.module').then((m: any) => m.ChampionshipsModule),
  },
  {
    path: 'hall-of-fame',
    loadChildren: () =>
      import('./features/hall-of-fame/hall-of-fame.module').then((m: any) => m.HallOfFameModule),
  },
  {
    path: '**',
    redirectTo: 'home',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
