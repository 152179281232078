import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {LoginData, RegisterData, User} from '../user/models/user';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Roles} from '../role/models/role';

@Injectable()
export class AuthenticationService {

  private user: BehaviorSubject<User>;
  public user$: Observable<User>;
  helper: JwtHelperService;

  constructor(private http: HttpClient) {
    this.user = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.user$ = this.user.asObservable();
    this.helper = new JwtHelperService();
  }

  private handleRefreshToken(refreshTokenResponse: RefreshTokenResponse): User {
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    const userString: string = localStorage.getItem('currentUser');
    if (userString) {
      const user: User = JSON.parse(userString);
      user.token = refreshTokenResponse.token;
      user.refreshToken = refreshTokenResponse.refresh_token;
      localStorage.setItem('currentUser', JSON.stringify(user));
      this.user.next(user);
      return user;
    } else {
      throw new Error('USER_NOT_STORED');
    }
  }

  public get userValue(): User {
    return this.user.value;
  }

  logged(): boolean {
    const userString: string = localStorage.getItem('currentUser');
    return !!userString;
  }

  getUser(): User {
    return this.user.value;
  }

  hasRole(role: Roles): boolean {
    if (this.getUser()) {
      const roles: Array<string> = this.helper.decodeToken(this.user.value.token).roles;
      if (roles) {
        return roles.includes(role);
      }
    }
  }

  hasOneRoleBetween(roles: Array<Roles>): boolean {
    let response: boolean = false;
    roles.forEach((r: Roles) => {
      response = response || this.hasRole(r);
    });
    return response;
  }

  getRoles(): Array<string> {
    return this.helper.decodeToken(this.user.value.token).roles;
  }

  makeLogout(): void {
    localStorage.removeItem('currentUser');
    this.user.next(null);
  }

  clearToken(): void {
    localStorage.removeItem('currentUser');
  }

  getExpirationDate(): Date {
    const user: User = this.user.getValue();
    if (user) {
      const token: string = user.token;
      if (token) {
        return this.helper.getTokenExpirationDate(token);
      }
    }
  }

  login(data: LoginData): Observable<User> {
    return this.http.post<LoginResponse>(`${environment.apiUrl}/login`, data)
      .pipe(map((loginResponse: LoginResponse) => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        const user: User = loginResponse.user;
        user.token = loginResponse.token;
        user.loginData = data;
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.user.next(user);
        return user;
      }));
  }

  loginCheck(data: LoginData): Observable<User> {
    return this.http.post<RefreshTokenResponse>(`${environment.apiUrl}/login-check`, data)
      .pipe(map((refreshTokenResponse: RefreshTokenResponse) => {
        return this.handleRefreshToken(refreshTokenResponse);
      }));
  }

  reloadToken(): Observable<User> {
    const userString: string = localStorage.getItem('currentUser');
    if (userString) {
      const user: User = JSON.parse(userString);
      if (user.loginData) {
        const loginData: LoginData = user.loginData;
        return this.loginCheck(loginData);
      } else {
        throw new Error('NO_LOGIN_DATA');
      }
    } else {
      throw new Error('USER_NOT_STORED');
    }
  }

  getCurrentUser(): User {
    const userString: string = localStorage.getItem('currentUser');
    return JSON.parse(userString);
  }

  refreshToken(): Observable<User> {
    const userString: string = localStorage.getItem('currentUser');
    if (userString) {
      const user: User = JSON.parse(userString);
      if (user.refreshToken) {
        const refreshToken: string = user.refreshToken;
        return this.http.post<RefreshTokenResponse>(`${environment.apiUrl}/token/refresh`, {refresh_token: refreshToken})
          .pipe(map((refreshTokenResponse: RefreshTokenResponse) => {
            return this.handleRefreshToken(refreshTokenResponse);
          }));
      } else {
        throw new Error('NO_REFRESH_TOKEN');
      }
    } else {
      throw new Error('USER_NOT_STORED');
    }
  }

  register(data: RegisterData): Observable<RegisterResponse> {
    return this.http.post<RegisterResponse>(`${environment.apiUrl}/register`, data);
  }

  ping(): Observable<PingResponse> {
    return this.http.get<PingResponse>(`${environment.apiUrl}/ping`);
  }

  auth(): Observable<PingResponse> {
    return this.http.get<PingResponse>(`${environment.apiUrl}/auth/ping`);
  }
}

export interface LoginResponse {
  user: User;
  token: string;
}

export interface RegisterResponse {
  user: User;
  token: string;
}

export interface PingResponse {
  OK: 'ok';
}

export interface RefreshTokenResponse {
  token: string;
  refresh_token: string;
}
