import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { environment } from '../../../environments/environment';
import { User } from '../services/user/models/user';
import { switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private authenticationService: AuthenticationService,
              private router: Router) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const currentUser: User = this.authenticationService.userValue;
    const isLoggedIn: boolean = (currentUser) && (currentUser.token !== undefined);
    const isApiUrl: boolean = request.url.startsWith(environment.apiUrl);
    if (isLoggedIn && isApiUrl) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      });
    }

    return next.handle(request).pipe(tap(() => {
      },
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status !== 401) {
            return;
          } else {
            try {
              this.authenticationService.reloadToken().pipe(switchMap(() => {
                return next.handle(request);
              }));
            } catch (refreshError) {
              this.authenticationService.makeLogout();
              this.router.navigateByUrl('/login');
            }
          }
        }
      }));
  }
}
