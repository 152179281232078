<mat-toolbar class="main-toolbar" color="primary">
  <div class="d-flex flex-row align-items-center">
    <div class="flag d-flex flex-column justify-content-between align-items-stretch">
      <div class="flag__colored flag--blue"></div>
      <div class="flag__colored flag--red"></div>
      <div class="flag__colored flag--black"></div>
      <div class="flag__colored flag--yellow"></div>
      <div class="flag__colored flag--green"></div>
    </div>
    <div class="button-container" [ngStyle]="{'display' : (screenWidth > 840) ? 'none' : 'block'}">
      <button class="menu-button" type="button" mat-button (click)="sidenav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
    </div>
    <img [routerLink]="'/'" [src]="'assets/images/logo.png'" class="logo home" alt="PCM IMA"/>
    <div [routerLink]="'/'" class="logo d-none d-md-block">PCM IMA</div>
    <app-theme-switch class="d-block"></app-theme-switch>
    <app-search-box class="search-box"></app-search-box>
  </div>
  <span></span>

  <div class="actions">
    <div class="social d-flex flex-row justify-content-start align-items-center  me-4">
      <a [href]="'https://discord.gg/jGUpEwwfRE'"
         [target]="'_blank'">
        <svg-icon class="social--discord" src="assets/images/socialicons/discord.svg"></svg-icon>
      </a>
      <a [href]="'https://www.facebook.com/pcmanagerima'"
         [target]="'_blank'">
        <svg-icon class="social--facebook" src="assets/images/socialicons/facebook.svg"></svg-icon>
      </a>
      <a [href]="'https://twitter.com/pcm_ima'"
         [target]="'_blank'">
        <svg-icon class="social--twitter" src="assets/images/socialicons/twitter.svg"></svg-icon>
      </a>
      <a [href]="'https://www.instagram.com/pcm_ima/'"
         [target]="'_blank'">
        <svg-icon class="social--instagram" src="assets/images/socialicons/instagram.svg"></svg-icon>
      </a>
      <a [href]="'https://www.twitch.tv/pcmima'" [target]="'_blank'">
        <svg-icon class="social--twitch" src="assets/images/socialicons/twitch.svg"></svg-icon>
      </a>
      <a [href]="'https://www.youtube.com/channel/UCnI2EBFr5CfxOYgWJZ9yv_g'"
         [target]="'_blank'">
        <svg-icon class="social--youtube" src="assets/images/socialicons/youtube.svg"></svg-icon>
      </a>
      <a [href]="'https://www.youtube.com/channel/UCvMd43Qb89FAiG4zW6gwZOw'"
         [target]="'_blank'">
        <svg-icon class="social--youtube" src="assets/images/socialicons/youtube.svg"></svg-icon>
      </a>
    </div>
    <div class="user actions__button" *ngIf="this.authenticationService.userValue">
      <mat-icon class="logged-in-icon" aria-hidden="false" aria-label="Example user verified icon">person_</mat-icon>&nbsp;
      <mat-chip-listbox>
        <mat-chip-option>{{this.authenticationService.getUser().username}}</mat-chip-option>
      </mat-chip-listbox>
    </div>
    <button class="actions__button" mat-raised-button [routerLink]="'/register'"
            *ngIf="!this.authenticationService.userValue">
      <mat-icon class="register-icon" aria-hidden="false" aria-label="Example user verified icon">person_add</mat-icon>
      {{'NAV_BAR.REGISTER' | translate}}
    </button>
    <button class="actions__button" mat-raised-button [routerLink]="'/login'"
            *ngIf="!this.authenticationService.userValue">
      <mat-icon class="login-icon" aria-hidden="false" aria-label="Example user verified icon">login</mat-icon>
      {{'NAV_BAR.LOGIN' | translate}}
    </button>
    <button class="actions__button" mat-raised-button (click)="logout()"
            *ngIf="this.authenticationService.userValue">
      <mat-icon class="logout-icon" aria-hidden="false" aria-label="Example user verified icon">logout</mat-icon>
      {{'NAV_BAR.LOGOUT' | translate}}
    </button>
  </div>
</mat-toolbar>
