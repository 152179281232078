import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../services/authentication/authentication.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {

  isRoot: boolean;

  constructor(private authenticationService: AuthenticationService) {
  }

  ngOnInit(): void {
    if (this.authenticationService.getUser()) {
      this.isRoot = this.authenticationService.getUser().isRoot;
    }
  }

}
