<app-nav-bar [screenWidth]="screenWidth" [sidenav]="sidenav"></app-nav-bar>
<mat-sidenav-container class="app-layout">
  <mat-sidenav class="side" #sidenav [opened]="screenWidth > 840"
               [mode]="(screenWidth > 840) ? 'side' : 'over'" [fixedTopGap]="64">
    <app-sidebar></app-sidebar>
  </mat-sidenav>
  <mat-sidenav-content class="content">
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
<div *ngIf="loaderService.isLoading | async" class="loader d-flex flex-column justify-content-center align-items-center">
  <mat-spinner></mat-spinner>
</div>
