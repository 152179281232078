import { Component, HostBinding, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { UtilService } from './core/services/util/util.service';
import { OverlayContainer } from '@angular/cdk/overlay';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @HostBinding('class') className: string = '';

  title: string = 'pcm-uci-angular';
  darkClassName: string = 'darkMode';

  refreshInterval: Subscription;

  constructor(private translate: TranslateService, private utilService: UtilService, private overlay: OverlayContainer) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('en');

    this.className = localStorage.getItem('darkMode') ? this.darkClassName : '';
  }

  ngOnInit(): void {
    this.utilService.darkMode.subscribe((darkMode: boolean) => {
      this.className = darkMode ? this.darkClassName : '';
      if (darkMode) {
        this.overlay.getContainerElement().classList.add(this.darkClassName);
      } else {
        this.overlay.getContainerElement().classList.remove(this.darkClassName);
      }
    });

  }

}
